import { useEffect, useState } from "react";
import "./style.css";
import {
  IonButton,
  IonIcon,
  IonNavLink,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonAvatar,
} from "@ionic/react";
import { idCardOutline, calendarOutline } from "ionicons/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import Lichday from "../../pages/Giangvien/lichday";
import Detail from "../../pages/Giangvien/detail";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

const SlideGiangVien: React.FC = () => {
  const url = "https://quiz.edu.vn/public/giangvien/";
  const [giangvien, setGiangvien] = useState<any[]>([]);

  const fetchData = async () => {
    const url = "https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/giangvien";
    try {
      const response = await fetch(url);
      const json = await response.json();
      setGiangvien(json.items);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        pagination={{
          clickable: true,
        }}
      >
        {giangvien.map((e) => {
          return (
            <SwiperSlide key={e.id} className="myswiper">
              <IonCard>
                <IonCardHeader>
                  <div className="header-card-inline">
                    <IonAvatar>
                      {e.img === "-1" ? (
                        <img
                          alt={e["name"]}
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPDAHKnh6lKTJ5B9Hwyl78qIe7nb52UQ9Qu7_rzELlk57iuA23GKBf-Sp4b2SzRX_Kf8k&usqp=CAU"
                          className="w-100"
                        />
                      ) : (
                        <img
                          alt={e["name"]}
                          src={url + e["img"]}
                          style={{ height: 150 }}
                        />
                      )}
                    </IonAvatar>
                    <div className="text-left">
                      <IonCardTitle style={{ fontSize: 20 }}>
                        {e["name"]}
                      </IonCardTitle>
                      <IonCardSubtitle>{e["chucdanh"]}</IonCardSubtitle>
                    </div>
                  </div>
                </IonCardHeader>
                <div className="ion-padding">
                  <IonNavLink
                    routerDirection="forward"
                    component={() => <Detail {...e} />}
                  >
                    <IonButton fill="outline" size="small">
                      <IonIcon slot="start" icon={idCardOutline}></IonIcon>
                      Chi tiết
                    </IonButton>
                  </IonNavLink>
                  <IonNavLink
                    routerDirection="forward"
                    component={() => <Lichday {...e} />}
                  >
                    <IonButton size="small">
                      <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                      Lịch dạy
                    </IonButton>
                  </IonNavLink>
                </div>
              </IonCard>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default SlideGiangVien;
