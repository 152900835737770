import React from "react";
import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCard,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonNavLink,
  IonBackButton,
} from "@ionic/react";
import { idCardOutline, calendarOutline } from "ionicons/icons";
import Lichday from "./lichday";
import Edit from "./edit";
import * as customjs from "../../custom.js";
function Index(e: any) {
  const url = "https://quiz.edu.vn/public/giangvien/";
  useIonViewWillEnter(() => {
    customjs.hideTab();
  });

  useIonViewWillLeave(() => {
    customjs.showTab();
  });
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="ionBackButton">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>Chi tiết giảng viên</IonTitle>
          <IonButtons slot="primary">
            <IonButton fill="solid">
              Help
              <IonIcon slot="end"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          {e.img === "-1" ? (
            <img
              alt={e.name}
              src="https://quiz.edu.vn/public/noimage.png"
              className="w-100"
            />
          ) : (
            <img alt={e.name} src={`${url}${e.img}`} />
          )}
          <IonCardHeader>
            <IonCardTitle>{e.name}</IonCardTitle>
            <IonCardSubtitle>{e.chucdanh}</IonCardSubtitle>
          </IonCardHeader>
          <div className="ion-padding">
            <IonButton fill="outline" size="small">
              <IonIcon slot="start" icon={idCardOutline}></IonIcon>
              <IonNavLink
                routerDirection="forward"
                component={() => <Edit {...e} />}
              >
                Sửa thông tin
              </IonNavLink>
            </IonButton>
            <IonNavLink
              routerDirection="forward"
              component={(props) => <Lichday {...e} />}
            >
              <IonButton size="small">
                <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                Xem lịch dạy
              </IonButton>
            </IonNavLink>
          </div>
        </IonCard>
      </IonContent>
    </>
  );
}

export default Index;
