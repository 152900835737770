import React from 'react';
import { IonIcon } from '@ionic/react';
import back from './back.svg';
import backOutline from './backOutline.svg';

export const SvgBack = () => (
  <IonIcon src={back}  size="large" />
);
export const SvgBackOutline = () => (
  <IonIcon src={backOutline}  size="large" />
);