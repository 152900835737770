import { useEffect, useState, useCallback } from "react";
import "./style.css";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonNavLink,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { newspaperOutline, idCardOutline } from "ionicons/icons";
import Detail from "../../pages/Lophoc/detail";
import Edit from "../../pages/Lophoc/edit";
import * as lophocService from "../../api/lophocService";

const lophocType = [
  "SC1",
  "SC2",
  "Boya1",
  "Boya2",
  "Boya3",
  "HSK4",
  "HSK5",
  "HSK6",
  "LuyenThiDH",
];
const DanhSachLopHoc: React.FC = () => {
  const [value, setValue] = useState<string>("SC1");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lophoc, setLophoc] = useState<any[]>([]);

  const fetchApi = useCallback(async (currentPage: number) => {
    const result = await lophocService.getLophoc(currentPage, 10);
    return result;
  }, []);

  const fetchApiLopHocByType = useCallback(async (ClassType: string) => {
    const result = await lophocService.getLophocByType(ClassType);
    console.log(result);
    return result;
  }, []);

  useEffect(() => {
    fetchApi(currentPage).then((result) => setLophoc([...lophoc, ...result]));
    console.log(lophoc);
  }, []);

  const generateItems = () => {
    setCurrentPage(currentPage + 10);
    fetchApi(currentPage).then((result) => setLophoc([...lophoc, ...result]));
  };
  const handleChange = (e: any) => {
    setValue(e.detail.value);
    fetchApiLopHocByType(e.detail.value).then((result) => setLophoc(result));
  };
  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>Lớp học</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={value}
            scrollable={true}
            onIonChange={handleChange}
          >
            {lophocType.map((e: string, index: number) => (
              <IonSegmentButton value={e} key={index}>
                {e}
              </IonSegmentButton>
            ))}
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        {lophoc.map((e, index) => (
          <IonCard key={index}>
            <IonCardHeader>
              <IonCardTitle>{e.name}</IonCardTitle>
              <IonCardSubtitle>{e.classtype}</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>Khai giảng: {e.khaigiang}</IonCardContent>
            <div className="ion-padding">
              <IonButton fill="outline" size="small">
                <IonNavLink
                  routerDirection="forward"
                  component={(props) => <Edit {...e} />}
                >
                  <IonIcon slot="start" icon={idCardOutline}></IonIcon> Chỉnh
                  sửa
                </IonNavLink>
              </IonButton>
              <IonButton size="small">
                <IonNavLink
                  routerDirection="forward"
                  component={(props) => <Detail {...e} />}
                >
                  <IonIcon slot="start" icon={newspaperOutline}></IonIcon> Chi
                  tiết
                </IonNavLink>
              </IonButton>
            </div>
          </IonCard>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </>
  );
};
export default DanhSachLopHoc;
