import * as httpRequest from '../utils/httpRequest';

export const getHocvien = async (limit, offset) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/allhocvien/${limit}/${offset}`, {
      params: {},
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
