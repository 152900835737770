import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent
} from "@ionic/react";
function EditLophoc() {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Chi tiết</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding"></IonContent>
    </>
  );
}

export default EditLophoc;
