import { IonPage, IonNav } from "@ionic/react";
import ListHocVien from "../../components/Hocvien/list";

const Hocvien: React.FC = () => {
  return (
    <IonPage>
      <IonNav root={() => <ListHocVien />}></IonNav>
    </IonPage>
  );
};

export default Hocvien;
