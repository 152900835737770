import React from "react";
import { useEffect } from "react";
import { IonPage, IonNav } from "@ionic/react";
import * as customjs from "../../custom.js";
import DashBoard from "../DashBoard";

const Lophoc: React.FC = () => {
  useEffect(() => {
    customjs.showTab();
  }, []);
  return (
    <IonPage>
      <IonNav root={() => <DashBoard />}></IonNav>
    </IonPage>
  );
};

export default Lophoc;
