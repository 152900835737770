import React, { createContext, useReducer } from "react";

let AppContext = createContext();

const initialState = {
  count: 0,
  showtab: false,
  userData:[],
  darkmode: false
}

let reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch(action.type) {
    case "setDarkmode": {
      return { ...state, darkmode: action.darkmode }
    }
    case "setCount": {
      return { ...state, count: action.count }
    }
    case "setTab": {
      return { ...state, showtab: (action.showtab) } 
    }
    case "setUserData": {
      return { ...state, userData: (action.userData) } 
    }
  }
  return state;
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState,
  }

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };
  

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };