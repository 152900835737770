import React from "react";
import {
  IonPage,
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCard,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonNavLink,
} from "@ionic/react";
import { idCardOutline } from "ionicons/icons";
import Danhsach from "./Index";
import * as customjs from "../../custom.js";
import { SvgBack } from "../../icons/Icon";
function Index(e: any) {
  customjs.hideTab();
  const handleBackBtn = () => {
    customjs.showTab();
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonNavLink
              onClick={handleBackBtn}
              routerDirection="back"
              component={() => <Danhsach />}
            >
              <SvgBack />
            </IonNavLink>
          </IonButtons>
          <IonTitle>Chi tiết</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{e.name}</IonCardTitle>
            <IonCardSubtitle>Học viên</IonCardSubtitle>
          </IonCardHeader>
          <div className="ion-padding">
            <IonButton fill="outline" size="small">
              <IonIcon slot="start" icon={idCardOutline}></IonIcon> {e.phone}
            </IonButton>
          </div>
        </IonCard>
      </IonContent>
    </>
  );
}

export default Index;
