import { useEffect, useState, useCallback } from "react";
import "./style.css";
import {
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonNavLink,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonSearchbar,
} from "@ionic/react";
import { callOutline, addCircleOutline } from "ionicons/icons";
import * as hocvienService from "../../api/hocvienService";
import Detail from "./../../pages/Hocvien/detail";

const ListHocVien: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hocvien, setHocvien] = useState<any[]>([]);
  console.log(hocvien);
  const fetchApi = useCallback(async (currentPage) => {
    const result = await hocvienService.getHocvien(currentPage, 10);
    return result;
  }, []);

  useEffect(() => {
    console.log("hocvien");
    fetchApi(currentPage).then((result) => setHocvien([...hocvien, ...result]));
  }, []);

  const generateItems = () => {
    console.log("loadmore");
    setCurrentPage(currentPage + 10);
    fetchApi(currentPage).then((result) => setHocvien([...hocvien, ...result]));
  };

  return (
    <>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Học viên</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList inset={true}>
            {hocvien &&
              hocvien.map(
                (e: { name: string; phone: string }, index: number) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>
                        <IonNavLink component={() => <Detail {...e} />}>
                          <div>{e.name}</div>
                        </IonNavLink>
                        {e.phone.replace(/\s/g, "") === "" ? (
                          <IonButton fill="outline">
                            <IonIcon
                              slot="start"
                              icon={addCircleOutline}
                            ></IonIcon>
                            Thêm số điện thoại
                          </IonButton>
                        ) : (
                          <IonButton>
                            <IonIcon slot="start" icon={callOutline}></IonIcon>
                            {e.phone}
                          </IonButton>
                        )}
                      </IonLabel>
                    </IonItem>
                  );
                }
              )}
          </IonList>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              generateItems();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
    </>
  );
};

export default ListHocVien;
