import React, { useCallback, useEffect, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCard,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { Timeline } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { idCardOutline, addCircleOutline, callOutline } from "ionicons/icons";
import * as LophocServices from "../../api/lophocService";
import * as customjs from "../../custom.js";
customjs.hideTab();
function Index(e: any) {
  const [value, setValue] = useState<string>("tientrinhhoc");
  const [chitietlophoc, setChitietlophoc] = useState<any[]>([]);
  const [danhsachhocvien, setDanhsachhocvien] = useState<any[]>([]);
  const [chuyenCanLopHoc, setChuyenCanLopHoc] = useState<any[]>([]);
  const [baiKiemTra, setBaiKiemTra] = useState<any[]>([]);
  useEffect(() => {
    setChitietlophoc(e);
  }, []);
  const fetchListHocVienByClass = useCallback(async () => {
    const result = await LophocServices.getHocvienByGroup(e.hocvien);
    return result;
  }, []);
  const fetchChuyenCan = useCallback(async () => {
    const result = await LophocServices.getChuyenCanLopHoc(e.id);
    return result;
  }, []);
  const fetchBaiKiemTra = useCallback(async () => {
    const result = await LophocServices.getBaiKiemTraLopHoc(e.id);
    return result;
  }, []);

  useEffect(() => {
    fetchListHocVienByClass().then((result) => setDanhsachhocvien(result));
    fetchChuyenCan().then((result) => setChuyenCanLopHoc(result));
    fetchBaiKiemTra().then((result) => setBaiKiemTra(result));
  }, []);

  const handleChange = (e: any) => {
    setValue(e.detail.value);
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="ionBackButton">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>Chi tiết</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{chitietlophoc["name"]}</IonCardTitle>
            <IonCardSubtitle>
              Khai giảng: {chitietlophoc["khaigiang"]}
            </IonCardSubtitle>
          </IonCardHeader>
          <div className="ion-padding">
            <IonButton fill="outline" size="small">
              <IonIcon slot="start" icon={idCardOutline}></IonIcon>
              Sửa thông tin
            </IonButton>
          </div>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonSegment value={value} onIonChange={handleChange}>
              <IonSegmentButton value="tientrinhhoc">
                Tiến trình học
              </IonSegmentButton>
              <IonSegmentButton value="danhsachlop">
                Danh sách lớp
              </IonSegmentButton>
              <IonSegmentButton value="baikiemtra">
                Bài kiểm tra
              </IonSegmentButton>
            </IonSegment>
          </IonCardHeader>
          {value === "tientrinhhoc" && (
            <Timeline className="ion-padding">
              {chuyenCanLopHoc.map((e) => (
                <Timeline.Item
                  dot={<ClockCircleOutlined className="timeline-clock-icon" />}
                  color="red"
                  key={e.id}
                >
                  <p>{e.ngayhoc}</p>
                  <div>
                    Buổi học {e.buoihoc}: {e.noidung}
                  </div>
                  <div>{e.tengiaovien}</div>
                </Timeline.Item>
              ))}
            </Timeline>
          )}
          {value === "danhsachlop" && (
            <IonList inset={true}>
              {danhsachhocvien &&
                danhsachhocvien.map((e, index) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>
                        <div>{e.name}</div>
                        {e.phone.replace(/\s/g, "") === "" ? (
                          <IonButton fill="outline">
                            <IonIcon
                              slot="start"
                              icon={addCircleOutline}
                            ></IonIcon>
                            Thêm số điện thoại
                          </IonButton>
                        ) : (
                          <IonButton>
                            <IonIcon slot="start" icon={callOutline}></IonIcon>
                            {e.phone}
                          </IonButton>
                        )}
                      </IonLabel>
                    </IonItem>
                  );
                })}
            </IonList>
          )}
          {value === "baikiemtra" && (
            <IonList inset={true}>
              {baiKiemTra &&
                baiKiemTra.map((e, index) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>
                        <div>{e.name}</div>
                      </IonLabel>
                    </IonItem>
                  );
                })}
            </IonList>
          )}
        </IonCard>
      </IonContent>
    </>
  );
}

export default Index;
