import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonIcon,
  IonSkeletonText,
  IonThumbnail,
  IonNavLink,
} from "@ionic/react";
import { callOutline, addCircleOutline } from "ionicons/icons";
import Detail from "../../pages/Giangvien/detail";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
const ListGiangVien: React.FC = () => {
  const url = "https://quiz.edu.vn/public/giangvien/";
  const [giangvien, setGiangvien] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);
  const array1 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const history = useHistory();

  const fetchData = async () => {
    const url = "https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/giangvien";
    try {
      const response = await fetch(url);
      const json = await response.json();
      setGiangvien(json.items);
      setLoaded(true);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    setLoaded(false);
    fetchData();
  }, []);
  const handleClick = useCallback(() => {
    history.push("/");
  }, []);
  return (
    <>
      {loaded && (
        <>
          <IonList inset={true}>
            {giangvien.map((e) => {
              return (
                <IonItem key={e.id}>
                  <IonAvatar slot="start">
                    {e.img === "-1" ? (
                      <img
                        alt={e.name}
                        src="https://quiz.edu.vn/public/noimage.png"
                      />
                    ) : (
                      <img alt={e.name} src={url + e.img} />
                    )}
                  </IonAvatar>
                  <IonLabel>
                    <IonNavLink
                      routerDirection="forward"
                      component={() => <Detail {...e} />}
                    >
                      <div>{e.name}</div>
                    </IonNavLink>
                    {e.phone.replace(/\s/g, "") === "" ? (
                      <IonButton
                        fill="outline"
                        routerDirection="forward"
                        onClick={handleClick}
                      >
                        <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
                        Thêm số điện thoại
                      </IonButton>
                    ) : (
                      <IonButton>
                        <IonIcon slot="start" icon={callOutline}></IonIcon>
                        {e.phone}
                      </IonButton>
                    )}
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </>
      )}
      {!loaded && (
        <IonList>
          {array1.map((e) => {
            return (
              <IonItem key={e}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      )}
    </>
  );
};

export default ListGiangVien;
