import React, { useEffect, useState, useCallback } from "react";
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonLoading,
  IonIcon,
  IonNavLink,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Lichdaydetail from "./lichdaydetail";
import * as giangvienService from "../../api/giangvienService";

function Index(e) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [chamcongtgiangvien, setChamcongiangvien] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const fetchApi = useCallback(async (currentPage) => {
    const result = await giangvienService.getLichDay(e.id, currentPage, 10);
    return result;
  }, []);

  useEffect(() => {
    fetchApi(currentPage).then((result) =>
      setChamcongiangvien([...chamcongtgiangvien, ...result])
    );
    setLoaded(true);
    console.log(chamcongtgiangvien);
  }, [currentPage]);

  const generateItems = () => {
    setCurrentPage(currentPage + 10);
    fetchApi(currentPage).then((result) =>
      setChamcongiangvien([...chamcongtgiangvien, ...result])
    );
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="ionBackButton">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>Lịch dạy</IonTitle>
        </IonToolbar>
      </IonHeader>
      {loaded && (
        <IonContent>
          {chamcongtgiangvien.map((e, index) => (
            <IonCard key={index}>
              <IonCardHeader>
                <IonCardTitle>{e.noidung}</IonCardTitle>
                <IonCardSubtitle>{e.ngayday}</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>{e.classname}</IonCardContent>
              <div className="ion-padding">
                <IonNavLink
                  routerDirection="forward"
                  component={() => <Lichdaydetail {...e} />}
                >
                  <IonButton size="small">
                    <IonIcon slot="start" icon={calendarOutline}></IonIcon>
                    Chi tiết
                  </IonButton>
                </IonNavLink>
              </div>
            </IonCard>
          ))}
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              generateItems();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      )}
      {!loaded && (
        <IonLoading
          cssClass="custom-loading"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(loaded)}
          message={"Please wait..."}
          duration={5000}
        />
      )}
    </>
  );
}

export default Index;
