import * as httpRequest from '../utils/httpRequest';

export const getLichDay = async (id,limit,offset) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/chamcongpaginator/${id}/${limit}/${offset}`, {
      params: {},
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};