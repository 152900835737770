import React, { useState } from "react";
import {
  IonButtons,
  IonBackButton,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonAvatar,
} from "@ionic/react";
import { SvgBack } from "../../icons/Icon";
import * as customjs from "../../custom.js";
import Danhsach from "./danhsach";

function Index(e: any) {
  const [avatar, setAvatar] = useState<string>(e.img);
  const urlAvatar = `https://quiz.edu.vn/public/giangvien/${avatar}`;
  const [name, setName] = useState(e.name);
  const [chucdanh, setChucdanh] = useState(e.chucdanh);
  const [phone, setPhone] = useState(e.phone);
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  );

  function handleBackBtn() {
    customjs.showTab();
  }

  function handleInputChange(event) {
    // get the value of the name attribute
    const value = event.target.value;
    // set the corresponding state variable
    if (event.target.name === "name") {
      setName(value);
    } else if (event.target.name === "chucdanh") {
      setChucdanh(value);
    } else if (event.target.name === "phone") {
      setPhone(value);
    }
  }
  function handleImageChange(event: any) {
    setSelectedImage(event.target.files![0]);
  }
  const openFileDialog = () => {
    (document as any).getElementById("file-upload").click();
  };
  const savedata = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("chucdanh", chucdanh);
    formData.append("phone", phone);
    formData.append("image", selectedImage!);

    // send the form data to the server using fetch or some other HTTP library
    fetch("/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="ionBackButton">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>Sửa thông tin</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div style={{ textAlign: "center" }}>
          <IonAvatar style={{ display: "inline-block" }}>
            {selectedImage ? (
              <IonAvatar>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected image"
                  height={40}
                />
              </IonAvatar>
            ) : (
              <>
                {avatar === "-1" ? (
                  <img src="https://quiz.edu.vn/public/noimage.png" />
                ) : (
                  <img src={urlAvatar} alt="" />
                )}
              </>
            )}
          </IonAvatar>
          <br />
          <IonLabel>
            <IonButton onClick={openFileDialog}>
              {avatar == "-1" ? <div>Thêm avatar</div> : <div>Thay avatar</div>}
            </IonButton>
          </IonLabel>
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
        <IonList>
          <IonItem>
            <IonLabel position="floating">Tên giảng viên</IonLabel>
            <IonInput
              name="name"
              value={name}
              onInput={handleInputChange}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Chức danh</IonLabel>
            <IonInput
              name="chucdanh"
              value={chucdanh}
              onInput={handleInputChange}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Số điện thoại</IonLabel>
            <IonInput
              name="phone"
              value={phone}
              onInput={handleInputChange}
            ></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton color="medium" expand="block">
                  Hủy
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" onClick={savedata}>
                  Lưu
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </>
  );
}

export default Index;
