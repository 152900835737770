import React from "react";
import { useEffect } from "react";
import { IonPage, IonNav } from "@ionic/react";
import DanhSachLopHoc from "../../components/DanhSachLopHoc/index";
import "./Lophoc.css";
import * as customjs from "../../custom.js";

const Lophoc: React.FC = () => {
  useEffect(() => {
    customjs.showTab();
  }, []);
  return (
    <IonPage>
      <IonNav root={() => <DanhSachLopHoc />}></IonNav>
    </IonPage>
  );
};

export default Lophoc;
