import React from "react";
import { useEffect } from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";

function Index(e) {
  useEffect(() => {}, []);
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="ionBackButton">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>Chi tiết buổi dạy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <IonCard key={e.id}>
          <IonCardHeader>
            <IonCardTitle>Nội dung dạy: {e.noidung}</IonCardTitle>
            <IonCardSubtitle>{e.ngayday}</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <p>Lớp học: {e.classname}</p>
            <p>Giảng viên: {e.teacher}</p>
            <p>Chấm công: {e.nguoidiemdanh}</p>
            <p>Nghỉ học: {e.nghi}</p>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </>
  );
}

export default Index;
