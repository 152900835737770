import React, { useContext, useEffect } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle } from '@ionic/react';
import ListGiangVien from '../../components/Giangvien/list';
import { AppContext } from '../../State';
import * as customjs from '../../custom.js';

const Index: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  

  useEffect(() => {
    if (!state.showtab) {
      customjs.showTab();console.log('showtab');
      dispatch({ type: 'setTab', showtab: true });
    }
  }, [state.showtab, dispatch]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Danh sách Giảng viên</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ListGiangVien />
      </IonContent>
    </>
  );
};

export default Index;
