import React, { useContext } from 'react';
import { message, Form, Button, Input } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { IonContent, IonCard, IonCardContent } from '@ionic/react';
import * as UsersServices from '../../api/usersService';
import * as customjs from "../../custom.js";
import { AppContext } from "../../State";
import { useHistory } from 'react-router-dom';
import "./style.css";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
customjs.hideTab();
const LoginPage: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { state, dispatch } = useContext(AppContext); console.log(state);
  const handleLogin = async (values) => {
    try {
      const result = await UsersServices.login(values);
      if (result.error === false) {
        dispatch({
          type: "setUserData",
          userData: result,
        })
      } else {
        message.error(result.message);
      }
      history.push('/');
    } catch (error) {
      console.error(error);
      // handle login error
    }
  };

  return (
    <IonContent >
      <IonCard>
        <IonCardContent>
          <Form {...layout} name="nest-messages" onFinish={handleLogin} form={form}>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Insert email account' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
              <div className="d-flex space-between">
                <Button type="primary" icon={<LoginOutlined />} htmlType="submit">
                  Đăng nhập
                </Button>
              </div>
            </Form.Item>
          </Form>
        </IonCardContent>
      </IonCard>
    </IonContent>
  );
};

export default LoginPage;
