export function hideTab() {
  const tabBar = document.getElementById("appTabBar");
  if (tabBar !== null) {
    tabBar.style.display = "none";
  }
}
export function showTab() {
  const tabBar = document.getElementById("appTabBar");
  if (tabBar !== null) {
    tabBar.style.display = "flex";
  }
}
