import * as httpRequest from '../utils/httpRequest';
import { getUser } from '../utils/Common';
import axios from 'axios';

const currentUser = getUser();
if (currentUser) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${currentUser.apiKey}`;
}

export const searchTasks = async (q) => {
  try {
    const res = await httpRequest.get('https://restapi.quiz.edu.vn/api_work/v1/searchtasks', {
      params: {
        q,
      },
    });
    return res.tasks;
  } catch (error) {
    console.log(error);
  }
};

export const login = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('email', data.email);
  bodyFormData.append('password', data.password);
  try {
    const res = await httpRequest.post(`https://restapi.quiz.edu.vn/api_work/v1/login`, bodyFormData);
    return res;
  } catch (error) {
    console.log(error);
  }
};