import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  bookOutline,
  peopleOutline,
  schoolOutline,
  homeOutline,
} from "ionicons/icons";
import Home from "./pages/Home/Index";
import Lophoc from "./pages/Lophoc/Index";
import Hocvien from "./pages/Hocvien/Index";
import Giangvien from "./pages/Giangvien/Index";
import LoginPage from "./pages/Login/Index";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AppContextProvider } from "./State";

setupIonicReact();

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/lophoc">
                <Lophoc />
              </Route>
              <Route exact path="/hocvien">
                <Hocvien />
              </Route>
              <Route path="/giangvien">
                <Giangvien />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route exact path="/login">
                <LoginPage />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" id="appTabBar">
              <IonTabButton tab="home" href="/home">
                <IonIcon icon={homeOutline} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="lophoc" href="/lophoc">
                <IonIcon icon={bookOutline} />
                <IonLabel>Lớp học</IonLabel>
              </IonTabButton>
              <IonTabButton tab="hocvien" href="/hocvien">
                <IonIcon icon={schoolOutline} />
                <IonLabel>Học viên</IonLabel>
              </IonTabButton>
              <IonTabButton tab="giangvien" href="/giangvien">
                <IonIcon icon={peopleOutline} />
                <IonLabel>Giảng viên</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </AppContextProvider>
  );
};

export default App;
