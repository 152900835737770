import * as httpRequest from '../utils/httpRequest';

export const getLophoc = async (limit, offset) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/alllophoc/${limit}/${offset}`, {
      params: {},
    });
    return res.lophoc;
  } catch (error) {
    console.log(error);
  }
};
export const getLophocByType = async (type) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/lophocbytype/${type}`, {
      params: {},
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getHocvienByGroup = async (group) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/hocvientheogroup/${group}`, {
      params: {
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getChuyenCanLopHoc = async (id) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/chuyencanlophoc/${id}`, {
      params: {
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBaiKiemTraLopHoc = async (id) => {
	try {
	  const res = await httpRequest.get(`https://restapi.quiz.edu.vn/hoaiphuonghsk/v1/baikiemtrabyclass/${id}`, {
		params: {
		},
	  });
	  return res.data;
	} catch (error) {
	  console.log(error);
	}
  };


