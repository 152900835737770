import { useState, useContext, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import {
  peopleOutline,
  receiptOutline,
  storefrontOutline,
  schoolOutline
} from "ionicons/icons";
import SlideGiangVien from "../components/Giangvien/slider";
import { AppContext } from "../State";
const DashBoard: React.FC = () => {
  const [toggleValue, setToggleValue] = useState(true);
  const { state, dispatch } = useContext(AppContext);
  const handleToggleChange = (e: any) => {
    setToggleValue(e.detail.checked);
    dispatch({
      type: "setDarkmode",
      darkmode: toggleValue,
    });
	checkThemme()
  };
  const checkThemme = () => {
    if (state.darkmode) {
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
    }
  };
  return (
    <>
      <IonMenu contentId="main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu Content</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonItem>
              <IonLabel>Chế độ tối {!state.darkmode && <>bật</>}</IonLabel>
              <IonToggle
                slot="end"
                checked={toggleValue}
                onIonChange={handleToggleChange}
              ></IonToggle>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonPage id="main-content">
        <IonContent className="ion-padding">
          <IonHeader>
            <IonToolbar className="customtoolbar">
              <IonButtons slot="start">
                <IonMenuButton className="customtoolbarbutton"></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className="bgindex">
            <div className="bg-index" />
          </div>
          <SlideGiangVien />
          <div className="box-thongke">
            <div className="item bg1">
              <div>
                <IonIcon icon={schoolOutline} size="large" />
                <h4>1234</h4>
                <div className="title">Học viên đang học</div>
              </div>
            </div>
            <div className="item bg2">
              <div>
                <IonIcon icon={peopleOutline} size="large" />
                <h4>1234</h4>
                <div className="title">Tổng số Học viên</div>
              </div>
            </div>
            <div className="item bg3">
              <div>
                <IonIcon icon={receiptOutline} size="large" />
                <h4>1234</h4>
                <div className="title">Tổng số Giảng viên</div>
              </div>
            </div>
            <div className="item bg4">
              <div>
                <IonIcon icon={storefrontOutline} size="large" />
                <h4>1234</h4>
                <div className="title">Tổng số Lớp học</div>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default DashBoard;
